import React, { useState, useReducer } from 'react';
import { Form, InputNumber, Col, Row, Button, Divider, Typography, Input, Upload, message, Table, DatePicker } from 'antd';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface'

import { Tokens, Deals, Files } from '@notcentralised/notvault-sdk';

const { Dragger } = Upload;
const { Paragraph } = Typography;
const { Column } = Table;

export const CreateDeal: React.FC<any> = (data) => {
    const [form] = Form.useForm();
    const [defaultFileList, setDefaultFileList] = useState<any>({data: []});
    const [isLoading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [tokens] = useState<Tokens>(new Tokens(data.vault));
    const [files] = useState<Files>(new Files(data.vault));
    const [deals] = useState<Deals>(new Deals(data.vault, tokens, files));

    let myClonedArray:any[] = [];

    const onSend = async (values: any) => {   
        setLoading(true);

        Object.keys(values).forEach(key => { try{ values[key] = values[key].unix(); } catch{} });
        
        // await deals.create(data.tokenAddress, undefined, values, defaultFileList);
        
        form.resetFields();
        data.closeModal();
        setLoading(false);
        setDefaultFileList({data: []});
    };

    const uploadFile = async (options: UploadRequestOption<any>) => {
        // eslint-disable-next-line
        const { onSuccess, onError, file, onProgress } = options;

        const buffer = await (file as File).arrayBuffer();
        const bytes = new Uint8Array(buffer);
        const b64 = Buffer.from(bytes).toString('base64');

        if(onSuccess){
            myClonedArray.push({ name: (file as File).name, data: b64, created: Date.now() })
            setDefaultFileList({ data: myClonedArray });
        
            onSuccess("Ok");
        }
    };

    const removeFile = async (name: string) => {
        myClonedArray = [];
        defaultFileList.data.forEach((val: any) => {
            if(val.name !== name) myClonedArray.push(Object.assign({}, val))
        }); 
        setDefaultFileList({ data: myClonedArray });
    }

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: async (x: any) => { 
            myClonedArray = [];
            if(defaultFileList.data)
                defaultFileList.data.forEach((val: any) => myClonedArray.push(Object.assign({}, val))); 
            await uploadFile(x);
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                forceUpdate();
            } 
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    return (
        <>
            <Form
                name="withdraw"
                form={form}
                onFinish={onSend}
                initialValues={{}}
                labelCol={{ span: 8 }}
            >
                <Divider orientation="left"></Divider>
                <Paragraph style={{textAlign:'left'}}>
                Create a deal and add collateral in the form of files.
                </Paragraph>
                <Divider orientation="left"></Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Counterpart" 
                            name="counterpart"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Information</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Name" 
                            name="name"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Description" 
                            name="description"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Notional" 
                            name="notional"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Initial Commitment" 
                            name="initial"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Payment Conditions</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Unlock Payer" 
                            name="unlock_sender"
                        >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Unlock Payee" 
                            name="unlock_receiver"
                        >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Expiry" 
                            name="expiry"
                        >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Approver" 
                            name="oracle_owner"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Key" 
                            name="oracle_key"
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Value" 
                            name="oracle_value"
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Files</Divider>
                {
                    defaultFileList.data.length > 0 ?
                    <Table 
                        dataSource={defaultFileList.data.map((x:any, i:number) => { x.key=i; return x})}
                        pagination={{hideOnSinglePage: true}}
                    >
                        <Column
                            title="Name"
                            key="name"
                            render={(_: any, record: any) => (
                                record.name
                            )}
                        />
                        <Column
                            title="Delete"
                            key="delete"
                            render={(_: any, record: any) => (
                                <Button 
                                type="default" 
                                block
                                onClick={async ()=> { removeFile(record.name); } }
                                >
                                    <DeleteOutlined />
                                </Button>
                            )}
                        />
                    </Table>
                    :
                    <></>
                }
                
                <Row gutter={16}>
                    <Col span={24}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                            Strictly prohibited from uploading illicit, unlawful, illegal data or other
                            banned files.
                            </p>
                        </Dragger>
                    </Col>
                </Row>
                <Divider orientation="left"></Divider>
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => { form.resetFields(); setLoading(false); data.closeModal(); }}
                        disabled={false}
                    >
                        Cancel
                    </Button>

                    <Button 
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}