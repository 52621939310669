import React, { useState, useEffect } from 'react';
import './App.css';

import { GoldOutlined, CloudServerOutlined, FallOutlined, RiseOutlined, FileDoneOutlined } from '@ant-design/icons';

import { Tabs, Input, Descriptions, Badge, Form, Col, Row, Layout, Button, Card, Typography, Divider, notification } from 'antd';

import { ethers } from 'ethers';

import { TokensUX } from './components/tokens/tokens';
import { FilesUX } from './components/files/files';
import { Assets } from './components/deals/assets';
import { Liabilities } from './components/deals/liabities';
import { CredentialsUX } from './components/credentials/credentials';

import { NotVault, contractsTable } from '@notcentralised/notvault-sdk';

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  return width;
}

function App() {
    const [isLoading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [walletData, setWalletData] = useState<any>({});

    // eslint-disable-next-line
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        api[type]({
            message: message,
            description: description
        });
    };

    
    const { ethereum } = window as any;

    const provider = new ethers.providers.Web3Provider(ethereum);
    const [vault] = useState<NotVault>(new NotVault());
    const [chainId, setChainId] = useState<string>('31337'); // localhost

    try{
        provider.getNetwork().then(x=>{
            setChainId(x.chainId.toString());

            const signer = provider.getSigner();
            const azure_functions_url = 'https://not-vault-functions.azurewebsites.net/api';
            // const azure_functions_url = 'https://api.sike.ai/api';
            // const azure_functions_url = 'http://localhost:7071/api';
            
            vault.init(
                x.chainId.toString(),
                signer,
                {
                    contracts: contractsTable[x.chainId.toString()],
                    proofs: Object.assign(
                        {}, ...[
                        { key: 'receiver', value: 'HashReceiver' }, 
                        { key: 'sender', value: 'HashSender' }, 
                        { key: 'approver', value: 'HashApprover' }, 
                        { key: 'paymentSignature', value: 'HashPaymentSignature' }, 
                        { key: 'textExpiryData', value: 'TextExpiryData' }, 
                        { key: 'textData', value: 'TextData' }, 
                        { key: 'numericalData', value: 'NumericalData' },
                        { key: 'alphaNumericalData', value: 'AlphaNumericalData' } 
                        ].map(element => ({
                            [element.key]: {
                                key:    process.env.PUBLIC_URL + `/zkp/${element.value}_0001.zkey`,
                                wasm:   process.env.PUBLIC_URL + `/zkp/${element.value}.wasm`,
                                vkey:   process.env.PUBLIC_URL + `/zkp/${element.value}_verification_key.json`
                            }
                        }))),
                    axios: {
                        get: (cid: string) => { 
                            return {
                                method: 'get',
                                // url: `${azure_functions_url}/Files?command=get&chainId=${x.chainId}&code=${process.env.AZURE_FUNCTION_KEY}&cid=${cid}`
                                url: `${azure_functions_url}/Files?command=get&chainId=${x.chainId}&cid=${cid}`
                            }
                        },
                        post: (fmData: FormData, onUploadProgress: any) => { 
                            return {
                                method: 'post',
                                // url: `${azure_functions_url}/Files?command=upload&chainId=${x.chainId}&code=${process.env.AZURE_FUNCTION_KEY}`,
                                url: `${azure_functions_url}/Files?command=upload&chainId=${x.chainId}`, 
                                data: fmData,
                                maxContentLength: Number.POSITIVE_INFINITY,
                                headers: {
                                    "Content-Type": `multipart/form-data; boundery=${(fmData as any)._boundary}`,
                                },
                                onUploadProgress: onUploadProgress
                            }
                        },
                        del: (cid: string) => { 
                            return {
                                method: 'get',
                                // url: `${azure_functions_url}/Files?command=delete&chainId=${x.chainId}&code=${process.env.AZURE_FUNCTION_KEY}&cid=${cid}`
                                url: `${azure_functions_url}/Files?command=delete&chainId=${x.chainId}&cid=${cid}`
                            }
                        }
                    }
                }   
            );
        });
    }
    catch(err: any){
        console.log(err);
        openNotificationWithIcon('error', err, "Error");
        setCurrent(0);
    }
    
    const sWidth = useCheckMobileScreen()
    
    const spanValue = sWidth < 768 ? 24 : sWidth < 1250 ? 20 : 16;
    const offsetValue = sWidth < 768 ? 0 : sWidth < 1250 ? 2 : 4;

    const smallSpanValue = sWidth < 768 ? 20 : sWidth < 1250 ? 16 : 8;
    const smallOffsetValue = sWidth < 768 ? 2 : sWidth < 1250 ? 4 : 8;

    // eslint-disable-next-line
    const { address, publicKey, contactId} = vault.getWalletData();

    const steps = [
    {
        title: 'Connect Wallet',
        content: 
        <Col span={smallSpanValue} offset={smallOffsetValue}>
            <Card>
                <Title level={2}>&nbsp;&nbsp;&nbsp; Layer-C</Title>
                <Divider plain></Divider>
                <Paragraph style={{textAlign:'left'}}>
                This App allows you to securely transfer tokens, send messages and store files within an encrypted infrastructure powered by Zero Knowledge Technology.
                </Paragraph>
                <Paragraph style={{textAlign:'left'}}>
                The Vault is a self-cutodial solution giving you and only you control over their data.
                </Paragraph>
                <Divider plain></Divider>
                <Button 
                    size="large"
                    style={{
                        height: "60px"
                    }} 
                    type="primary" 
                    danger
                    block
                    loading={isLoading}
                    onClick={async () => {
                        setLoading(true);
                        try{
                            ethereum
                            .request({
                                method: "eth_requestAccounts",
                            })
                            .then(async (accounts : string[]) => {
                                const address = accounts[0];
                                setWalletData({ address: address });

                                await vault.login(
                                    address, 
                                    async (data: string) => {  //Decrypt with crypt wallet
                                        return ethereum.request({
                                              method: 'eth_decrypt',
                                              params: [data, address],
                                        })
                                    },
                                    async (publicKey: string, contactId: string) => { // Success
                                        setLoading(false);
                                        setCurrent(3);
                                    },
                                    async () => { // Enter Secret
                                        setLoading(false);
                                        setCurrent(2);
                                    },
                                    async () => { // Register
                                        setLoading(false);
                                        setCurrent(1);
                                    }
                                );
                            })
                            .catch((error: any) => {
                                console.log(error)
                                openNotificationWithIcon('error', "Login", "Error with your login");
                            });
                        }
                        catch (error: any) {
                            openNotificationWithIcon('error', "Wallet", "We are not able to find your wallet extension");
                        };

                        setLoading(false);
                    }}
                >
                    Connect Wallet
                </Button>
            </Card>
        </Col>,
    },
    {
        title: 'Register Credentials',
        content: 
        <Col span={smallSpanValue} offset={smallOffsetValue}>
            <Card>
                <Title level={2}>Generate Credentials</Title>
                <Divider plain></Divider>
                <Paragraph style={{textAlign:'left'}}>
                You will need to unlock your NotVault access using Wallet.
                </Paragraph>
                <Form
                    name="register"
                    onFinish={async (data:any) => { 
                        let contactId = data.email;
                        const secretKey = data.secret;
                        setLoading(true);


                        const counterpart = walletData.address;

                        contactId = contactId.toLocaleLowerCase().trim();

                        await vault.register(
                            counterpart, 
                            contactId,
                            secretKey,
                            async () => { // Retrieve public key from crypto wallet
                                return await ethereum.request({
                                    method: 'eth_getEncryptionPublicKey',
                                    params: [counterpart],
                                }); 
                            },
                            async (publicKey: string, contactId: string) => { // Success
                                setLoading(false);
                                setCurrent(3);
                            }
                        );
                    }}
                    labelCol={{ span: 8 }}
                >
                    <Divider orientation="left"></Divider>
                    <br></br>
                    <Row gutter={16}>
                        <Col span={24}>
                        <Form.Item 
                            label="Email" 
                            name="email"
                            rules={[{ required: true, message: 'Please input a valid email', type: 'email' }]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item 
                            label="Secret" 
                            name="secret"
                            hasFeedback
                            rules={[{ required: true, message: 'Please input your secret', type: 'string' }]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Secret"
                            dependencies={['secret']}
                            hasFeedback
                            rules={[
                            {
                                required: true,
                                message: 'Please confirm your secret!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('secret') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two secrets that you entered do not match!'));
                                },
                            }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left"></Divider>
                    <Form.Item>
                        <Button 
                            type="primary"
                            htmlType="submit"
                            danger
                            block
                            loading={isLoading}
                            size="large"
                            style={{
                                height: "60px"
                            }} 
                        >
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Col>,
    },
    {
        title: 'Enter Secret',
        content: 
        <Col span={smallSpanValue} offset={smallOffsetValue}>
            <Card>
                <Title level={2}>Unlock with Secret</Title>
                <Divider plain></Divider>
                <Paragraph style={{textAlign:'left'}}>
                You will need to unlock your NotVault access using a Secret.
                </Paragraph>
                <Form
                    name="register"
                    onFinish={async (data:any) => { 
                        
                        await vault.enterSecret(
                            walletData.address, 
                            data.secret,
                            async (publicKey: string, contactId: string) => { // Success
                                setCurrent(3);
                            }); 
                    }}
                >
                    <Divider orientation="left"></Divider>
                    <br></br>
                    <Row gutter={16}>
                        <Col span={24}>
                        <Form.Item 
                            label="Secret" 
                            name="secret"
                            rules={[{ required: true, message: 'Please input a valid email', type: 'string' }]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left"></Divider>
                    <Form.Item>
                        <Button 
                            type="primary"
                            htmlType="submit"
                            danger
                            block
                            loading={isLoading}
                            size="large"
                            style={{
                                height: "60px"
                            }} 
                        >
                            Unlock
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Col>,
    },
    {
        title: 'App',
        content: 
        <Col span={spanValue} offset={offsetValue}>
            
            <Card>
                <Title level={2}>TradeFlows &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Layer C</Title>
                <Divider plain></Divider>
                <Descriptions bordered>
                <Descriptions.Item style={{textAlign:'left'}} label="Email" span={3}>
                    <Badge status="success" text={contactId} />
                </Descriptions.Item>
                <Descriptions.Item style={{textAlign:'left'}} label="Wallet Address" span={3}>
                    <Badge status="success" text={address} />
                </Descriptions.Item>
                <Descriptions.Item  style={{textAlign:'left'}}  label="Network" span={3}>
                    <Badge status="success" text={chainId === '5' ? 'Goerli' : chainId === '11155111' ? 'Sepolia' : chainId === '296' ?  'Hedera Testnet' : 'Localhost'} />
                </Descriptions.Item>
                </Descriptions>

                <br />
                <br />

                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="large"
                    items={[
                    {
                        label: (
                            <span>
                                <GoldOutlined />
                                Tokens
                            </span>
                            ),
                        key: '1',
                        children: 
                            <TokensUX 
                                chainId={chainId}
                                vault={vault}
                                logout={() => {
                                    setWalletData({ address: null });
                                    setCurrent(0);
                                }}
                            />
                    },
                    {
                        label: (
                            <span>
                                <RiseOutlined />
                                Assets
                            </span>
                            ),
                        key: '2',
                        children:
                        <>
                            <Assets 
                                chainId={chainId}
                                vault={vault}
                                logout={() => {
                                    setWalletData({ address: null });
                                    setCurrent(0);
                                }}
                            />
                        </>
                    },
                    {
                        label: (
                            <span>
                            <FallOutlined />
                            Liabilities
                            </span>
                        ),
                        key: '3',
                        children:
                        <>
                            <Liabilities 
                                chainId={chainId}
                                vault={vault}
                                logout={() => {
                                    setWalletData({ address: null });
                                    setCurrent(0);
                                }}
                            />
                        </>
                    },
                    {
                        label: (
                            <span>
                                <CloudServerOutlined />
                                Drive
                            </span>
                            ),
                        key: '4',
                        children:
                        <>
                            <FilesUX
                                chainId={chainId}
                                vault={vault}
                                logout={() => {
                                    setWalletData({ address: null });
                                    setCurrent(0);
                                }}
                            />
                        </>
                    },
                    {
                        label: (
                            <span>
                                <FileDoneOutlined />
                                Credentials
                            </span>
                            ),
                        key: '5',
                        children:
                        <>
                            <CredentialsUX
                                chainId={chainId}
                                vault={vault}
                                logout={() => {
                                    setWalletData({ address: null });
                                    setCurrent(0);
                                }}
                            />
                        </>
                    }
                ]} />
            </Card>
        </Col>,
    }
    ];

    const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    marginTop: 16,
    height: '100vmh'
    };

    return (
    <>
        <Layout 
            style={{ padding: sWidth < 768 ? '' : '0 50px' }}
        >
            <Header>
                <img
                    alt=''
                    src='../NC_logo.webp'
                    style={{ 
                        marginTop:10,
                        height: '80px'
                    }}
                >
                </img>
            </Header>
            <Content>
                <br></br>
                <div style={contentStyle}>{steps[current].content}</div>
            </Content>
        </Layout>
    </>
    );
}

export default App;
