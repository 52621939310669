import React, { useState, useEffect, useReducer } from 'react';
import { Switch, Upload, Form, InputNumber, Col, Row, Button, Divider, Input, Select, Space, DatePicker, message } from 'antd';
import { AuditOutlined, SecurityScanOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Credentials, Files, recoverSignature } from '@notcentralised/notvault-sdk';
import { CredentialTemplates, Schemas } from '../../utils/credential_templates';
import type { UploadProps } from 'antd';
// import { verify } from 'crypto';

const { RangePicker } = DatePicker;

export const Credential: React.FC<any> = (data) => {
    
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [isQuery, setIsQuery] = useState(data.query);
    const [isVerifier, setIsVerifier] = useState(data.verifier);
    const [query, setQuery] = useState<any>(undefined);
    const [proof, setProof] = useState<any>(undefined);
    const [documentType, setDocumentType] = useState(data.credential ? data.credential.raw.schema.id : Schemas[0].schema.id);

    // eslint-disable-next-line
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [files] = useState<Files>(new Files(data.vault));
    const [credentials] = useState<Credentials>(new Credentials(data.vault, files));

    const { Option } = Select;
    const { Dragger } = Upload;

    // eslint-disable-next-line
    useEffect(() => {
        form.resetFields();
        if(query !== undefined)
            setIsQuery(true);
        
        else if(isQuery !== data.query)
            setIsQuery(data.query);

        if(isVerifier !== data.verifier)
            setIsVerifier(data.verifier);
        
    // eslint-disable-next-line
    });

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: '.json',
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);
            const utf8 = Buffer.from(bytes).toString('utf8');

            if(isVerifier && !isQuery)
            {
                const proofObject = JSON.parse(utf8);
                setProof(proofObject);
            }
            else
            {
                const queryObject = JSON.parse(utf8);

                setQuery(queryObject);
                setIsQuery(true);
                forceUpdate();
            }      
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                // forceUpdate();
            } 
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const onIssue = async (values: any) => {   
        setLoading(true);

        Object.keys(values).forEach(key => { try{ values[key] = values[key].toISOString() } catch{} });

        let owner = values['__owner__'];

        if(data.credential && data.credential.issue){
            const source =  data.credential.data['__source__'];

            try{
                const pk = await data.vault.getPublicKeyByContactId(owner);
                if(pk !== source){
                    message.error(`${owner} doesn't match the source.`);
                    setLoading(false);
                    return;
                }
            }
            catch(err){
                console.log(err);
                message.error(`${owner} doesn't match the source.`);
                setLoading(false);
                return;
            }

        }

        let cleanValues : any = {}
        Object.keys(values).filter(x => !x.startsWith('__')).forEach(x => {
            cleanValues[x] = values[x];
        });

        const cred = await credentials.issue(cleanValues, { data: CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].schema }, owner, data.credential ? data.credential.raw.source : undefined, { read: true, write: true } );

        const file = new File([JSON.stringify(cred, null, 4)], 'cred.json', {type: 'application/json'});
        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = 'cred.json';
        document.body.appendChild(element);

        element.click();
        
        form.resetFields();
        data.closeModal();
        setLoading(false);
    };

    const onQuery = async (values: any) => {   
        const _query: Record<string, any> = {}
                    
        Object.keys(values).forEach(key => {
            if(values[key]){
                
                if(Object.keys(values[key]).length === 2){
                    if(values[key].min && values[key].max)
                        _query[key] = values[key];
                    else if(Array.isArray(values[key])){
                        _query[key] = [0, 1].map(i => new Date(values[key][i].unix() * 1000).toISOString());
                    }
                }
                else
                    _query[key] = values[key];
            }
        });


        if(query){
            let cleanData : any = {};
            Object.keys(data.credential.data).forEach(key => {
                if(!key.startsWith('__')){
                    cleanData[key] = data.credential.data[key];
                }
            });

            console.log(query)

            const proof = await credentials.prove(query, "", cleanData, data.credential.raw.schema);
            const name = `proof-${documentType}.json`

            const proofFile = new File([JSON.stringify(proof, null, 4)], name, { type: 'application/json'});
            const element = document.createElement("a");
            element.href = URL.createObjectURL(proofFile);
            element.download = name;
            document.body.appendChild(element);

            element.click();
        }
        // Generating a Query
        else{
            const name = `query-${documentType}.json`

            const file = new File([JSON.stringify(_query, null, 4)], name, {type: 'application/json'});
            const element = document.createElement("a");
            element.href = URL.createObjectURL(file);
            element.download = name;
            document.body.appendChild(element);

            element.click();
        }

        setQuery(undefined);
        setLoading(false); 
        data.closeModal(); 
        form.resetFields();
    };

    const onVerification = async (values: any) => {

        const cleanValues = values;
        const recovery = await recoverSignature({ message: proof.proof, signature: proof.signature });

        const isValid = await credentials.verify(proofDates(cleanValues), "", proof, recovery.signer);
        if(isValid)
            message.success(`Valid`);
        else
            message.error(`Invalid`);
    }

    const transformDates = (x: any) => {
        
        let xCopy = {...x}; 
        Object.keys(x).forEach((fieldKey, i) => {
            if(!fieldKey.startsWith('__')){
                const type = CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].type;
                if(type === 'date')
                    xCopy[fieldKey] = dayjs.unix(Date.parse(x[fieldKey]) / 1000);
            }
        })
        
        return xCopy;
    }

    const queryDates = (proof: any) => {

        let queryCopy = {...proof.query};
        Object.keys(proof.query).forEach((fieldKey, i) => {
            if(!fieldKey.startsWith('__')){
                const type = CredentialTemplates[proof.schema.id].fields[fieldKey].type;
                if(type === 'date')
                    queryCopy[fieldKey] = [dayjs.unix(Date.parse(proof.query[fieldKey][0]) / 1000), dayjs.unix(Date.parse(proof.query[fieldKey][1]) / 1000)];
                else if(type === 'string')
                    queryCopy[fieldKey] = undefined;
            }
        });

        return queryCopy;
    }

    const _queryDates = (query: any) => {

        if(query === undefined)
            return;

        let queryCopy : Record<string,any> = {};
        Object.keys(query).forEach((fieldKey, i) => {
            const val = query[fieldKey];
            
            if(Array.isArray(val))
                queryCopy[fieldKey] = [dayjs.unix(Date.parse(val[0]) / 1000), dayjs.unix(Date.parse(val[1]) / 1000)];
            else
                queryCopy[fieldKey] = val;

        });

        return queryCopy;
    }

    const proofDates = (values: any) => {
        let valuesCopy = {...values};

        Object.keys(values).forEach((fieldKey, i) => {
            if(!fieldKey.startsWith('__')){
                const type = CredentialTemplates[proof ? proof.schema.id : data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].type;
                if(type === 'date')
                    valuesCopy[fieldKey] = [values[fieldKey][0].unix() * 1000, values[fieldKey][1].unix() * 1000];
            }
        });

        return valuesCopy;
    }

    return (
        <>
            <br></br>
            {
                data.credential ?
                <>
                </>
                :
                !isVerifier ?
                <>
                    <Select
                        size="large"
                        style={{ width: '100%' }}
                        defaultValue={data.credential ? data.credential.raw.schema.id : documentType}
                        onChange={(val:string) => {setDocumentType(val)}}
                        optionLabelProp="label"
                        disabled={data.credential}
                    >
                        {
                            Object.keys(CredentialTemplates).map((template, i) => 
                                <Option value={template} label={CredentialTemplates[template].name} key={i}>
                                    <Space>
                                        {CredentialTemplates[template].name}
                                    </Space>
                                </Option>
                            )
                        }
                    </Select>
                    
                </>
                :
                <></>
            }
            <Divider orientation="left"></Divider>
            
            {
                !isQuery && !isVerifier?
                <Form
                    name="issue"
                    form={form}
                    onFinish={onIssue}
                    labelCol={{ span: 8 }}
                    initialValues={data.credential ? transformDates(data.credential.data) : {}}
                >
                    <br></br>
                    {
                        !data.credential || (data.credential && (data.credential.__owner__ || data.credential.issue)) ?
                            <Row gutter={16} key='-3'>
                                <Col span={24}>
                                    <Form.Item 
                                        label="Owner"
                                        name="__owner__"
                                        rules={[{ required: true, message: 'Please input request' }]}
                                    >
                                        <Input disabled={data.credential && !data.credential.issue}/>
                                        
                                    </Form.Item>
                                </Col>
                            </Row>
                        :
                            <></>
                    } 

                    {
                        !data.credential ?
                        <>
                        </>
                        :
                        <Row gutter={16} key='-1'>
                            <Col span={24}>
                                <Form.Item 
                                    label={data.credential.data.__selfIssued__ ? <>Issuer &nbsp;<WarningOutlined /></> :  <>Issuer &nbsp;<CheckCircleOutlined /></> }
                                    name="__issuer__"
                                    rules={[{ required: true, message: 'Please input request' }]}
                                >
                                    <Input disabled={data.credential}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    
                    <Divider orientation="left">{CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].name}</Divider>
                    { Object.keys(CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields).map((fieldKey: any, i: number) => 
                        (
                            <Row gutter={16} key={i}>
                                <Col span={24}>
                                    <Form.Item 
                                        label={CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].name}
                                        name={fieldKey} 
                                        rules={[{ required: true, message: 'Please input request' }]}
                                    >
                                        {
                                            (CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].type) === 'number' ? 
                                            <InputNumber
                                                disabled={data.credential}
                                                min={0} 
                                                style={{ width: '100%' }}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                            :
                                            (CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].type) === 'date' ?
                                            <DatePicker 
                                                disabled={data.credential}
                                                style={{ width: '100%' }}
                                                showTime 
                                                format="YYYY-MM-DD HH:mm:ss" />
                                            :
                                            <Input disabled={data.credential}/>
                                        }
                                        
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    )}
                    
                    <Divider orientation="left"></Divider>
                    {
                        data.credential ?
                        <>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <SecurityScanOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag "Query" to generate a proof</p>
                            <p className="ant-upload-hint">
                            Only json files in a query format will work.
                            </p>
                        </Dragger>
                        <br></br>
                        </>
                        :
                        <></>
                    }
                    <Form.Item>
                        <Button 
                            style={{
                                position: "absolute",
                                width: "120px",
                                left: 0,
                                transform: "translateY(-50%)"
                            }} 
                            onClick={() => { 
                                setProof(undefined);
                                setQuery(undefined);
                                form.resetFields(); 
                                setLoading(false); 
                                data.closeModal(); }}
                            disabled={false}
                        >
                            Cancel
                        </Button>
                        {
                            !data.credential ?
                            <Button 
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    width: "120px",
                                    transform: "translateY(-50%)"
                                }} 
                            >
                                Create
                            </Button>
                            :
                            data.credential.issue ?
                            <>
                                <Button 
                                    type="primary"
                                    loading={isLoading}
                                    style={{
                                        position: "absolute",
                                        right: 175,
                                        width: "120px",
                                        transform: "translateY(-50%)"
                                    }} 
                                    htmlType="submit"
                                >
                                    Issue
                                </Button>
                                <Button 
                                    type="primary"
                                    loading={isLoading}
                                    style={{
                                        position: "absolute",
                                        right: 0,
                                        width: "120px",
                                        transform: "translateY(-50%)"
                                    }}
                                    onClick={async () => {            
                                        setLoading(true);        
                                        const wallet = data.vault.getWalletData();
                                        await data.credentials.add(JSON.stringify({ owner: wallet.contactId, id: data.credential.raw.id, type: data.credential.raw.schema.id }), JSON.stringify(data.credential.raw));

                                        form.resetFields();
                                        data.closeModal();
                                        setLoading(false);
                                    }}
                                >
                                    Save
                                </Button>
                            </>
                            :
                            <>
                                <Button 
                                    type="primary"
                                    loading={isLoading}
                                    style={{
                                        position: "absolute",
                                        right: 0,
                                        width: "120px",
                                        transform: "translateY(-50%)"
                                    }}
                                    onClick={async () => {                    
                                        const cred = data.credential.raw;                                
                                        const name = `credential-${cred.id}-${cred.schema.id}.json`;

                                        const file = new File([JSON.stringify(data.credential.raw, null, 4)], name, {type: 'application/json'});
                                        const element = document.createElement("a");
                                        element.href = URL.createObjectURL(file);
                                        element.download = name;
                                        document.body.appendChild(element);

                                        element.click();
                                    }}
                                >
                                    Download
                                </Button>
                            </>
                        }
                        
                    </Form.Item>
                </Form>
                :
                isVerifier && !isQuery ?
                <Form
                    name="verifier"
                    form={form}
                    onFinish={onVerification}
                    labelCol={{ span: 8 }}
                    initialValues={ proof ? queryDates(proof) : undefined }
                >      
                    { proof ? 
                        <>
                            <Divider orientation="left">{CredentialTemplates[proof.schema.id].name}</Divider>
                            {Object.keys(CredentialTemplates[proof.schema.id].fields).map((fieldKey: any, i: number) => 
                            (
                                (
                                    proof.query[fieldKey] ?
                                    <Row gutter={16} key={i}>
                                        <Col span={24}>
                                            <Form.Item 
                                                label={CredentialTemplates[proof.schema.id].fields[fieldKey].name}
                                                name={(CredentialTemplates[proof.schema.id].fields[fieldKey].type) === 'number' ? undefined: fieldKey} 
                                            >
                                                {
                                                    (CredentialTemplates[proof.schema.id].fields[fieldKey].type) === 'number' ? 
                                                    <Space.Compact>
                                                        <Form.Item
                                                            name={[fieldKey, 'min']}
                                                            noStyle      
                                                        >
                                                            <InputNumber
                                                                disabled
                                                                min={0} 
                                                                style={{ width: '100%' }}
                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[fieldKey, 'max']}
                                                            noStyle
                                                        >
                                                            <InputNumber
                                                                disabled
                                                                min={0} 
                                                                style={{ width: '100%' }}
                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            />
                                                        </Form.Item>
                                                    </Space.Compact>

                                                    
                                                    :
                                                    (CredentialTemplates[proof.schema.id].fields[fieldKey].type) === 'date' ?
                                                    <RangePicker 
                                                        disabled
                                                        style={{ width: '100%' }}
                                                        showTime 
                                                        format="YYYY-MM-DD HH:mm:ss" />
                                                    :
                                                    <Input />
                                                }
                                                
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    :
                                    <div key={i}></div>
                                )
                            ))}
                        </>
                    : 
                        <></>}
                    {
                        <>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <AuditOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag "Proof" for verification.</p>
                            <p className="ant-upload-hint">
                            Only json files in a proof format will work.
                            </p>
                        </Dragger>
                        <br></br>
                        </>
                    }
                    
                    <Divider orientation="left"></Divider>
                    <Form.Item>
                        <Button 
                            style={{
                                position: "absolute",
                                width: "120px",
                                left: 0,
                                transform: "translateY(-50%)"
                            }} 
                            onClick={() => {
                                setQuery(undefined);
                                setProof(undefined);
                                setLoading(false); 
                                data.closeModal(); 
                                form.resetFields();
                            }}
                            disabled={false}
                        >
                            Cancel
                        </Button>
                        <Button 
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            style={{
                                position: "absolute",
                                right: 0,
                                width: "120px",
                                transform: "translateY(-50%)"
                            }} 
                        >
                            Verify
                        </Button>
                    </Form.Item>
                </Form>
                :
                <Form
                    name="query"
                    form={form}
                    onFinish={onQuery}
                    labelCol={{ span: 8 }}
                    initialValues={_queryDates(query)}
                >          
                    <Divider orientation="left">{CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].name}</Divider>
                    { Object.keys(CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields).map((fieldKey: any, i: number) => 
                        (
                            <Row gutter={16} key={i}>
                                <Col span={24}>
                                    <Form.Item 
                                        label={CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].name}
                                        name={(CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].type) === 'number' ? undefined: fieldKey} 

                                        valuePropName={(CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].type) === 'string' ? 'checked' : undefined }
                                    >
                                        {
                                            (CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].type) === 'number' ? 
                                            <Space.Compact>
                                                <Form.Item
                                                    name={[fieldKey, 'min']}
                                                    noStyle      
                                                >
                                                    <InputNumber
                                                        disabled={query !== undefined}
                                                        min={0} 
                                                        style={{ width: '100%' }}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[fieldKey, 'max']}
                                                    noStyle
                                                >
                                                    <InputNumber
                                                        disabled={query !== undefined}
                                                        min={0} 
                                                        style={{ width: '100%' }}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    />
                                                </Form.Item>
                                            </Space.Compact>

                                            
                                            :
                                            (CredentialTemplates[data.credential ? data.credential.raw.schema.id : documentType].fields[fieldKey].type) === 'date' ?
                                            <RangePicker 
                                                disabled={query !== undefined}
                                                style={{ width: '100%' }}
                                                showTime 
                                                format="YYYY-MM-DD HH:mm:ss" />
                                            :
                                            <Switch disabled={query !== undefined} />
                                        }
                                        
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    )}
                    
                    <Divider orientation="left"></Divider>
                    <Form.Item>
                        <Button 
                            style={{
                                position: "absolute",
                                width: "120px",
                                left: 0,
                                transform: "translateY(-50%)"
                            }} 
                            onClick={() => {
                                setProof(undefined);
                                setQuery(undefined);
                                setLoading(false); 
                                data.closeModal(); 
                                form.resetFields();
                            }}
                            disabled={false}
                        >
                            Cancel
                        </Button>
                        <Button 
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            style={{
                                position: "absolute",
                                right: 0,
                                width: "120px",
                                transform: "translateY(-50%)"
                            }} 
                        >
                            { query ? 'Prove' : 'Generate' }
                        </Button>
                    </Form.Item>
                </Form>
            }
        </>
    );
}