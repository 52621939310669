import React, { useEffect, useState, useReducer } from 'react';

import { Button, DatePicker, FloatButton, Upload, message, Table, Spin, Alert, Space } from 'antd';
import { HomeOutlined, LogoutOutlined, InboxOutlined, LoadingOutlined, CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import type { UploadProps } from 'antd';

import { UploadRequestOption } from 'rc-upload/lib/interface'

import { Files, decryptBySecret } from '@notcentralised/notvault-sdk';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Column } = Table;
const { Dragger } = Upload;

const truncate = (str : string) => {
    if(str)
        return str.length > 15 ? str.substring(0, 5) + "..." + str.substring(str.length - 5, str.length) : str;
    else
        return str;
}

export const FilesUX: React.FC<any> = (data) => {
    const [defaultFileList, setDefaultFileList] = useState<{ data: any[] }>({data: []});
    // eslint-disable-next-line
    const [progress, setProgress] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeletingId, setDeletingId] = useState({ Id: '', Name: ''});
    // eslint-disable-next-line
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [files] = useState<Files>(new Files(data.vault));

    let myClonedArray:any[] = [];

    const removeFile = async (cid: string) => {
        setLoading(true);
        const newFiles = await files.remove(cid);
        setDefaultFileList({ data: newFiles });
        setLoading(false);
        setDeleting(false);
    }
            
    const uploadFile = async (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;

        const buffer = await (file as File).arrayBuffer();
        const bytes = new Uint8Array(buffer);
        const b64 = Buffer.from(bytes).toString('base64');

        try {

            const fileName = (file as File).name;

            const newList = await files.add(
                fileName, 
                b64, 
                (event: any) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    setProgress(percent);
                    if (percent === 100) {
                        setTimeout(() => setProgress(0), 1000);
                    }
                    if(onProgress)
                        onProgress({ percent: (event.loaded / event.total) * 100 });
                }
            );
            setLoading(false);
                        
            if(onSuccess){
                onSuccess("Ok");
                setDefaultFileList({ data: newList });
            }
        } 
        catch (err: any) {
            if(onError)
                onError(err);
        }
    };
      
    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: async (x: any) => { 
            setLoading(true);
            myClonedArray = [];
            if(defaultFileList.data)
                defaultFileList.data.forEach((val: any) => myClonedArray.push(Object.assign({}, val))); 
            await uploadFile(x);
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                forceUpdate();
            } 
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };
    

    useEffect(() => {
        setLoading(true);
        files.list().then((index: any) => {
            setDefaultFileList({ data: index });
            setLoading(false);
        });
    // eslint-disable-next-line
    }, [0]);


    return (
    <>
        { isLoading ?
            <>
                <Alert
                    message="Blockchain magic is happening..."
                    type="warning"
                />
                <br></br>
                <br></br>
                <Spin indicator={antIcon}></Spin>
            </>
            :
            <>
                { isDeleting ?
                    <Alert
                        
                        message={`Please confirm the deletion of file: ${isDeletingId.Name} | (${isDeletingId.Id})`}
                        type="warning"
                        action={
                            <Space direction="vertical">
                                <Button size="small" type="primary" danger onClick={async () => { await removeFile(isDeletingId.Id)}}>
                                    Remove
                                </Button>
                                <Button size="small" danger type="dashed" onClick={() => setDeleting(false) }>
                                    Cancel
                                </Button>
                            </Space>
                        }
                    />
                :
                    <></>
                }
                <Table 
                    dataSource={defaultFileList.data.map((x, i) => {
                        x.key = i;
                        return x;
                    })}
                    pagination={{hideOnSinglePage: true}}
                >

                    <Column
                        title="CID"
                        key="type"
                        render={(_: any, record: any) => (
                            truncate(record.cid)
                        )}
                    />
                    <Column
                        title="Name"
                        key="name"
                        render={(_: any, record: any) => (
                            record.meta
                        )}
                    />
                    <Column
                        title="Created"
                        key="created"
                        render={(_: any, record: any) => (
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.created / 1000)} />
                        )}
                    />
                    <Column
                        title="Delete"
                        key="delete"
                        render={(_: any, record: any) => (
                            <Button 
                            type="default" 
                            block
                            onClick={async ()=> { setDeletingId({ Id: record.cid, Name: record.meta}); setDeleting(true) } }
                            >
                                <DeleteOutlined />
                            </Button>
                        )}
                    />
                    <Column
                        title="Download"
                        key="download"
                        render={(_: any, record: any) => (
                            <Button 
                            type="default" 
                            block
                            onClick={async () => {
                                const secret = record.secret;
                                const name = record.meta;
                                const cid = record.cid;

                                const encryptedData = await files.get(cid);

                                let _data = decryptBySecret(secret, encryptedData);
                                const file = new File([Buffer.from(_data,"base64")], name, {type: 'application/octet-stream'});
                                const element = document.createElement("a");
                                element.href = URL.createObjectURL(file);
                                element.download = name;
                                document.body.appendChild(element);
                                element.click();
                            }}
                            >
                                <CloudDownloadOutlined />
                            </Button>
                        )}
                    />
                </Table>

                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                    Strictly prohibited from uploading illicit, unlawful, illegal data or other
                    banned files.
                    </p>
                </Dragger>
            </>
        }

        <FloatButton.Group
            type="default"
            style={{ right: 50 }}
            icon={<HomeOutlined />}
        >
            <FloatButton tooltip={<div>Logout</div>} icon={<LogoutOutlined />} onClick={async () => {
                // const permissions = await ethereum.request({
                //   method: 'wallet_requestPermissions',
                //   params: [{
                //     eth_accounts: {},
                //   }]
                // });
                // setWalletData({ address: null });
                // setCurrent(0);
                data.logout();
            }}/>
        </FloatButton.Group>
    </>
    );
}