import React, { useState, useEffect, useReducer } from 'react';

import { Table, Space, Select, Image, Col, Row, Statistic, FloatButton, Modal, Button, Divider, DatePicker } from 'antd';
import { HomeOutlined, LogoutOutlined, KeyOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { CreateDeal } from './createdeal';
import { AcceptDeal } from './commitdeal';
import { ViewDeal } from './viewdeal';
import { Approve } from './approve';

import { Tokens, Deals, Files } from '@notcentralised/notvault-sdk';

import { currencyAddresses, currencies, uxUpdateFrequency } from '../../utils/config';

const { Column } = Table;

export const Liabilities: React.FC<any> = (data) => {
    const [currency, setCurrency] = useState<string>("usdc");
    const [dealCounterpartData, setDealCounterpartData] = useState<any[]>([]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [balanceData, setBalanceData] = useState<any>({});

    const [notional, setNotional] = useState(BigInt(0));
    const [totalLocked, setTotalLocked] = useState(BigInt(0));

    const [isApprovalModalOpen, setApprovalModalOpen] = useState(false);

    const [deal, setDeal] = useState<{deal: any, type: number}>({deal: null, type: 0});
    // eslint-disable-next-line
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [tokens] = useState<Tokens>(new Tokens(data.vault));
    const [files] = useState<Files>(new Files(data.vault));
    const [deals] = useState<Deals>(new Deals(data.vault, tokens, files));
    
    const { Option } = Select;

    // eslint-disable-next-line
    const { address, publicKey, contactId } = data.vault.getWalletData();

    useEffect(() => {
        const id = setInterval(() => {

            // deals.getLiabilities()
            // .then(x => {
            //     const filtered = x.filter(x => x.denomination === currencyAddresses[data.chainId][currency]);
            //     const total_notional = filtered.reduce((acc, current) => acc + current.notional, BigInt(0));
            //     const total_locked = filtered.reduce((acc, current) => acc + current.metatotal_locked, BigInt(0));
            //     setNotional(total_notional);
            //     setTotalLocked(total_locked);
            //     setDealCounterpartData(filtered);
            // });

            // tokens.getBalance(currencyAddresses[data.chainId][currency]).then(data => {
            //     setBalanceData({ 
            //         balance: data.balance / data.decimals, 
            //         privateBalance: data.privateBalance / data.decimals, 
            //     });
            // });
        }, uxUpdateFrequency); 
        
        return () => clearInterval(id); 
    });

    return (
        <>
            <Select
                size="large"
                style={{ width: '100%' }}
                defaultValue={'usdc'}
                onChange={(val:string) => {setCurrency(val)}}
                optionLabelProp="label"
            >
                <Option value="usdc" label="USDC">
                    <Space>
                    <span role="img" aria-label="usdc">
                        <Image height={20} src={currencies["usdc"].url} ></Image>
                    </span>
                    USDC
                    </Space>
                </Option>

                <Option value="weth" label="wETH">
                    <Space>
                    <span role="img" aria-label="weth">
                        <Image height={20} src={currencies["weth"].url} ></Image>
                    </span>
                    wETH
                    </Space>
                </Option>

                <Option value="wbtc" label="wBTC">
                    <Space>
                    <span role="img" aria-label="wbtc">
                        <Image height={20} src={currencies["wbtc"].url} ></Image>
                    </span>
                    wBTC
                    </Space>
                </Option>
            </Select>

            <br />
            <br />
            <br />

            <Row gutter={16}>
                <Col span={12}>
                    <Statistic
                        prefix={<Image height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                        title="Total Notional"
                        value={notional.toLocaleString()}
                        precision={2}
                    />
                </Col>
                <Col span={12}>
                    <Statistic
                        prefix={<Image height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                        title="Total Locked"
                        value={totalLocked.toLocaleString()}
                        precision={2}
                    />
                </Col>
            </Row>
            
            <br />
            <Divider orientation="left">Deals</Divider>
            
            <Table 
                dataSource={
                    dealCounterpartData
                }
                pagination={{hideOnSinglePage: true}}
            >
                <Column
                    title="Name"
                    key="name"
                    render={(_: any, record: any) => (
                        record.name
                    )}
                />
                <Column
                    title="Counterpart"
                    key="counterpart"
                    render={(_: any, record: any) => record.owner}
                />
                <Column
                    title="Target"
                    key="notional"
                    render={(_: any, record: any) => (
                        <><Image height={15} style={{marginTop: "-2px"}} src={currencies[currency].url} /> {(record.notional).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
                    )}
                />
                <Column
                    title="Locked"
                    key="locked"
                    render={(_: any, record: any) => (
                        <><Image height={15} style={{marginTop: "-2px"}} src={currencies[currency].url} /> {(record.total_locked).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
                    )}
                />
                <Column
                    title="Timestamp"
                    key="timestamp"
                    render={(_: any, record: any) => (
                        <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Math.max(record.acceptedTime, record.createdTime))} />
                    )}
                />
                <Column
                    title="Action"
                    key="action"
                    render={(_: any, record: any) => (
                        <Button 
                            type="default" 
                            block
                            onClick={async ()=> { 
                                
                                if(record.acceptedTime > 0 || record.counterpart.toLowerCase() !== address.toLowerCase()){
                                    setDeal({deal: record, type: 2}); 
                                    forceUpdate();
                                }
                                else{
                                    setDeal({deal: record, type: 1}); 
                                    forceUpdate();
                                }
                                
                            }}
                        >
                            
                            {record.acceptedTime > 0 || record.counterpart.toLowerCase() !== address.toLowerCase() ? 'View' : 'Commit'}
                        </Button>
                    )}
                />
            </Table>

            <FloatButton.Group
                type="default"
                style={{ right: 50 }}
                icon={<HomeOutlined />}
            >
                <FloatButton tooltip={<div>Approve</div>} icon={ <KeyOutlined /> } onClick={()=> { setApprovalModalOpen(true); }} />
                <FloatButton tooltip={<div>Logout</div>} icon={<LogoutOutlined />} onClick={async () => {
                    // const permissions = await ethereum.request({
                    //   method: 'wallet_requestPermissions',
                    //   params: [{
                    //     eth_accounts: {},
                    //   }]
                    // });
                    // setWalletData({ address: null });
                    // setCurrent(0);
                    data.logout();
                }}/>
            </FloatButton.Group>

            <Modal title="Create Deal" closable={false} open={isCreateModalOpen} footer={null}>
                <CreateDeal 
                    vault={data.vault}
                    tokenAddress={currencyAddresses[data.chainId][currency]}
                    closeModal={() => {setCreateModalOpen(false);}}
                />
            </Modal>
            <Modal title="Commit Deal" closable={false} open={deal.type === 1} footer={null}>
                <AcceptDeal 
                    balanceData={balanceData}
                    vault={data.vault}
                    tokenAddress={currencyAddresses[data.chainId][currency]}
                    deal={deal.deal}
                    closeModal={() => {setDeal({deal: null, type: 0});}}
                />
            </Modal>
            <Modal title="View Deal" closable={false} open={deal.type === 2} footer={null}>
                <ViewDeal 
                    increase={true}
                    vault={data.vault}
                    tokenAddress={currencyAddresses[data.chainId][currency]}
                    deal={deal.deal}
                    closeModal={() => {setDeal({deal: null, type: 0});}}
                />
            </Modal>
            <Modal title="Approve" closable={false} open={isApprovalModalOpen} footer={null}>
                <Approve 
                    increase={true}
                    vault={data.vault}
                    tokenAddress={currencyAddresses[data.chainId][currency]}
                    deal={deal.deal}
                    closeModal={() => {setApprovalModalOpen(false);}}
                />
            </Modal>
        </>
    );
}