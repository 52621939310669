import React, { useState } from 'react';
import { Form, Input, InputNumber, Col, Row, Button, Divider, Alert } from 'antd';

import { Tokens } from '@notcentralised/notvault-sdk';

export const Transfer: React.FC<any> = (data) => {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);

    const [tokens] = useState<Tokens>(new Tokens(data.vault));

    const onSend = async (values: any) => {    
        
        setLoading(true);
        
        // eslint-disable-next-line
        const { balance, decimals} = await tokens.tokenBalance(data.tokenAddress);

        console.log(values)

        const amount = BigInt(values.destination_amount) * decimals;

        // await tokens.send(data.tokenAddress, values.destination_address, amount);
        form.resetFields();
        data.closeModal();
        setLoading(false);
    };

    return (
        <>
            <Form
                name="send_amount"
                form={form}
                onFinish={onSend}
                initialValues={{}}
                labelCol={{ span: 8 }}
            >
                <Divider orientation="left"></Divider>
                <br></br>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            style={{width:'100%'}}
                            name="destination_address"
                            label="Destination Address"
                            rules={[{ required: true, message: 'Please pick a term' }]}
                            >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Amount" 
                            name="destination_amount"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left"></Divider>
                {
                    data.balanceData.privateBalance < form.getFieldValue('destination_amount') ? 
                    <>
                        <Alert
                            
                            message="The private balance is not enough to commit the initial amount..."
                            type="error"
                        />
                        <br></br>
                    </>
                    :
                    <></>
                }
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => { form.resetFields(); setLoading(false);data.closeModal(); }}
                        disabled={false}
                    >
                        Cancel
                    </Button>

                    <Button 
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={data.balanceData.privateBalance < form.getFieldValue('destination_amount')}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Send
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}