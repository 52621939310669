
import { Schema } from '@notcentralised/notvault-sdk';
export interface IIndexable { [key: string]: Schema }

export const Schemas : { name: string, schema: Schema }[] = [
    {
        name: 'Passport',
        schema: {
            id: 'passport',
            type: 'alphaNumericalData',
            fields: [
                {
                    id: 'id',
                    name: 'Passport Number',
                    type: 'number'
                },
                {
                    id: 'first_name',
                    name: 'First Name',
                    type: 'string'
                },
                {
                    id: 'last_name',
                    name: 'Last Name',
                    type: 'string'
                },
                {
                    id: 'dob',
                    name: 'Date of Birth',
                    type: 'date'
                },
                {
                    id: 'country',
                    name: 'Country',
                    type: 'string'
                }
            ]
        }
    },
    {
        name: 'University Credential',
        schema: {
            id: 'university_credential',
            type: 'alphaNumericalData',
            fields: [
                {
                    id: 'university_name',
                    name: 'University Name',
                    type: 'string'
                },
                {
                    id: 'graduate',
                    name: 'Graduate',
                    type: 'number'
                },
                {
                    id: 'course',
                    name: 'Course',
                    type: 'string'
                },
                {
                    id: 'grade',
                    name: 'Grade',
                    type: 'number'
                }
            ]
        }
    }
]

const CreateTemplates = () => {
    let credentialsObject : any = {}
    Schemas.forEach(element => {
        credentialsObject[element.schema.id] = {};
        credentialsObject[element.schema.id].id = element.schema.id;
        credentialsObject[element.schema.id].schema = element.schema;
        credentialsObject[element.schema.id].name = element.name;
        
        credentialsObject[element.schema.id]['fields']={}
        element.schema.fields.forEach(field => {
            credentialsObject[element.schema.id]['fields'][field.id] = field;
        })
    });
    return credentialsObject;
}

export const CredentialTemplates = CreateTemplates();
