import React, { useEffect, useState } from 'react';

import { DatePicker, Image, Modal, FloatButton, Form, Statistic, Col, Row, Button, Divider, Table, Select, Space, Alert } from 'antd';
import { RiseOutlined, FallOutlined, HomeOutlined, LogoutOutlined, SendOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Transfer } from './transfer';
import { Deposit } from './deposit';
import { Withdraw } from './withdraw';

import { Tokens } from '@notcentralised/notvault-sdk';

import { currencyAddresses, currencies, uxUpdateFrequency } from '../../utils/config';

const { Column } = Table;

const truncate = (str : string) => {
    if(str)
        return str.length > 20 ? str.substring(0, 10) + "..." + str.substring(str.length - 10, str.length) : str;
    else
        return str;
}

export const TokensUX: React.FC<any> = (data) => {

    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
    const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

    const [balanceData, setBalanceData] = useState<any>({});
    const [poolData, setPoolData] = useState<any>({});
    const [currency, setCurrency] = useState<string>("usdc");

    const [isProcessingRecord, setProcessingRecord] = useState(false);
    const [activeRecord, setActiveRecord] = useState<any>({amount: null, decimals: 0});

    const { Option } = Select;

    const [tokens] = useState<Tokens>(new Tokens(data.vault));

    // eslint-disable-next-line
    const { address, publicKey, contactId } = data.vault.getWalletData();
    
    const onReceive = async (values: any) => {
        // let idHash = values.hash_id;
        // tokens.retreive(idHash, currencyAddresses[data.chainId][data.currency]);
    };

    // useEffect(() => {
    //     const id = setInterval(() => {
    //         tokens.getBalance(currencyAddresses[data.chainId][currency]).then(data => {
    //             setPoolData({ decimals: data.decimals, lockedIn: data.lockedIn, lockedOut: data.lockedOut, locked: data.lockedIn.concat(data.lockedOut).map((x: any, i: number) => { x.key=i; return x }) });

    //             setBalanceData({                         
    //                 balance: data.balance / data.decimals,
    //                 privateBalance: data.privateBalance / data.decimals, 
    //                 lockedIn: BigInt(data.lockedIn.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? currentValue.amount : BigInt(0)), BigInt(0))) / data.decimals, 
    //                 lockedOut: BigInt(data.lockedOut.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? currentValue.amount : BigInt(0)), BigInt(0))) / data.decimals
    //             });
    //         });
        
    //     }, uxUpdateFrequency); 

    //     try{
    //         data.vault.setValue('A', 'B').then((txTest : any) => {
    //             console.log('---- TX TEST')
    //             console.log(txTest)
    //         })
    //     }
    //     catch{}

    //     return () => clearInterval(id); 
    // // eslint-disable-next-line        
    // }, []);
    
    return (
    <>
        <Select
            size="large"
            style={{ width: '100%' }}
            defaultValue={'usdc'}
            onChange={(val:string) => { setCurrency(val); }}
            optionLabelProp="label"
        >
            {
                Object.keys(currencies).map((currency, i) => 
                    <Option value={currency} label={currencies[currency].label} key={i+1}>
                        <Space>
                            <span role="img" aria-label={currency}>
                                <Image height={20} src={currencies[currency].url} ></Image>
                            </span>
                            {currencies[currency].label}
                        </Space>
                    </Option>
                )
            }
        </Select>
        
        <br />
        <br />
        <br />

        <Row gutter={16}>
            <Col span={6}>
                <Statistic
                prefix={<Image height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                title="Public Balance"
                value={balanceData.balance ? balanceData.balance : 0}
                precision={2}
                />
            </Col>
            <Col span={6}>
                <Statistic
                    prefix={<Image height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                    title="Private Balance"
                    value={balanceData.privateBalance ? balanceData.privateBalance : 0}
                    precision={2}
                />
            </Col>
            <Col span={6}>
                <Statistic
                    prefix={<Image height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                    title="Locked Liabilities"
                    value={balanceData.lockedOut ? balanceData.lockedOut : 0}
                    precision={2}
                />
            </Col>
            <Col span={6}>
                <Statistic
                    prefix={<Image height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                    title="Locked Assets"
                    value={balanceData.lockedIn ? balanceData.lockedIn : 0}
                    precision={2}
                />
            </Col>
        </Row>
        
        <br />
        <Divider orientation="left">Accept & Withdraw</Divider>
        { isProcessingRecord ?
            <Alert
                
                message={`Please confirm the processing of transfer: ${( activeRecord.amount / poolData.decimals).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                type="warning"
                action={
                    <Space direction="vertical">
                        
                        {/* <Button size="small" type="primary" danger onClick={async ()=> { await tokens.retreive(activeRecord.idHash, currencyAddresses[data.chainId][currency]); setProcessingRecord(false); }}>
                            Process
                        </Button> */}
                        <Button size="small" danger type="dashed" onClick={() => setProcessingRecord(false) }>
                            Cancel
                        </Button>
                    </Space>
                }
            />
        :
            <></>
        }
        <Form
            name="receive_amount"
            onFinish={onReceive}
            initialValues={{}}
            labelCol={{ span: 8 }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Table 
                        dataSource={poolData.locked ? poolData.locked.filter((x : any) => x.active) : []}
                        pagination={{hideOnSinglePage: true}}
                    >
                        <Column
                            title="Type"
                            key="type"
                            render={(_: any, record: any) => (
                                record.sender.toLowerCase() !== address.toLowerCase() ? <RiseOutlined twoToneColor="#52c41a" /> : <FallOutlined twoToneColor="#eb2f96" />
                            )}
                        />
                        <Column
                            title="Counterpart"
                            key="couterpart"
                            render={(_: any, record: any) => (
                                record.sender.toLowerCase() === address.toLowerCase() ? truncate(record.recipient) : truncate(record.sender)
                            )}
                        />
                        <Column
                            title="Amount"
                            key="amount"
                            render={(_: any, record: any) => (
                                <><Image height={15} style={{marginTop: "-2px"}} src={currencies[currency].url} /> {(record.amount / poolData.decimals).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
                            )}
                        />
                        <Column
                            title="Created"
                            key="created"
                            render={(_: any, record: any) => (
                                <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.created)} />
                            )}
                        />
                        <Column
                            title="Unlock"
                            key="unlock"
                            render={(_: any, record: any) => (
                                <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.sender.toLowerCase() === address.toLowerCase() ? record.unlock_sender : record.unlock_receiver)} />
                            )}
                        />
                        <Column
                            title="Action"
                            key="action"
                            render={(_: any, record: any) => (
                                <Button 
                                type="default" 
                                block
                                disabled={!record.active || (record.sender.toLowerCase() === address.toLowerCase() ? record.unlock_sender * 1000 > Date.now() : record.unlock_receiver * 1000 > Date.now())}                                
                                onClick={() => { setActiveRecord(record); setProcessingRecord(true); }}
                                >
                                    {record.sender.toLowerCase() === address.toLowerCase() ? 'Cancel' : 'Redeem' }
                                </Button>
                            )}
                        />
                    </Table>
                </Col>
            </Row>
        </Form>

        <FloatButton.Group
            type="default"
            style={{ right: 50 }}
            icon={<HomeOutlined />}
        >
            <FloatButton tooltip={<div>Private Transfer</div>} icon={<SendOutlined />} onClick={()=> {setIsTransferModalOpen(true);}} />
            <FloatButton tooltip={<div>Deposit</div>} icon={<PlusCircleOutlined />} onClick={()=> {setIsDepositModalOpen(true);}} />
            <FloatButton tooltip={<div>Withdraw</div>} icon={<MinusCircleOutlined />} onClick={()=> {setIsWithdrawModalOpen(true);}} />
            <FloatButton tooltip={<div>Logout</div>} icon={<LogoutOutlined />} onClick={async () => {
                // const permissions = await ethereum.request({
                //   method: 'wallet_requestPermissions',
                //   params: [{
                //     eth_accounts: {},
                //   }]
                // });
                // setWalletData({ address: null });
                // setCurrent(0);
                data.logout();
            }}/>
        </FloatButton.Group>
    
        <Modal title="Transfer" closable={false} open={isTransferModalOpen} footer={null}>
            <Transfer 
                balanceData={balanceData}
                vault={data.vault}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsTransferModalOpen(false);}}
            />
        </Modal>

        <Modal title="Deposit" closable={false} open={isDepositModalOpen} footer={null}>
            <Deposit 
                balanceData={balanceData}
                vault={data.vault}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsDepositModalOpen(false);}}
            />
        </Modal>

        <Modal title="Withdraw" closable={false} open={isWithdrawModalOpen} footer={null}>
            <Withdraw 
                balanceData={balanceData}
                vault={data.vault}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsWithdrawModalOpen(false);}}
            />
        </Modal>
    </>
    );
}