export const currencies : Record<string, any> = {
    'usdc': {
        label: 'USDC',
        url: process.env.PUBLIC_URL + '/images/stablecoin.png'
    },
    'weth': {
        label: 'wETH',
        url: process.env.PUBLIC_URL + '/images/ethereum.png'
    },
    'wbtc': {
        label: 'wBTC',
        url: process.env.PUBLIC_URL + '/images/bitcoin.png'
    }
};

export const currencyAddresses : Record<string, Record<string, string>> = {
    '5': {              // GOERLI
        'usdc':         '0xD8B4ee409E54B326143Cf57D603772a750a023a4',
        'weth':         '0x988dee89519b7B6631BdE8c4A7cCF34D1dDB94b9',
        'wbtc':         '0xbc37E80c65e2Bf6A7eC57dC7554c59c79F686895'
    },
    '11155111': {       // SEPOLIA
        'usdc':         '0xd261bf9e88b90fFF6C02Fff03A6ED790b7a18C25',
        // 'usdc':         '0x109518B06F1271fC6bC28066a3f7e6Cd79cF95D3', // eAUD
        'weth':         '0x8839A35175663992D0a04dB4EF995112D659C9b8',
        'wbtc':         '0x0B6D528fc723CCb06e852965DFC95290BE43D185'
    },
    '296': {            // HEDERA TESTNET
        'usdc':         '0xc936DDCbAa7b74C7a71B05ad9EbAffD1f09C23c3',
        'weth':         '0x3b61682BECc845781769417A83170f692CE3e761',
        'wbtc':         '0x42E5b23f1de2E7C88903a023a30Fe8f99924c175'
    },
    '31337': {          // LOCALHOST
        'usdc':         '0x0B306BF915C4d645ff596e518fAf3F9669b97016',
        'weth':         '0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1',
        'wbtc':         '0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE'
    },
    '84531': {          // BASE GOERLI
        'usdc':         '0xa86eb07c9e9f8c441a3cae2a286f4B1445B4352D',
        'weth':         '0x1445b695146008A37965b9C5bB7E8093f216E5a6',
        'wbtc':         '0x5Bd5880535d7dEfB64a3F386f1AD5490d87e2473'
    }
};

export const uxUpdateFrequency = 1000;