import React, { useState, useEffect, useReducer } from 'react';

import { Upload, Modal, Button, DatePicker, FloatButton, Table, Spin, Alert, Space, message } from 'antd';
import { FileSearchOutlined, VerifiedOutlined, FileProtectOutlined, HomeOutlined, LogoutOutlined, LoadingOutlined, EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import type { UploadProps } from 'antd';

import { Credentials, Files, decryptBySecret, recoverSignature, Credential as NCred } from '@notcentralised/notvault-sdk';

import { Credential } from './credential';

import { CredentialTemplates } from '../../utils/credential_templates';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Column } = Table;
const { Dragger } = Upload;

const truncate = (str : string) => {
    if(str)
        return str.length > 15 ? str.substring(0, 5) + "..." + str.substring(str.length - 5, str.length) : str;
    else
        return str;
}

export const CredentialsUX: React.FC<any> = (data) => {
    const [defaultFileList, setDefaultFileList] = useState<{ data: any[] }>({data: []});
    const [isLoading, setLoading] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeletingId, setDeletingId] = useState({ Id: '', Name: ''});
    // eslint-disable-next-line
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
    
    const [credential, setCredential] = useState<any>();
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isQueryModalOpen, setQueryModalOpen] = useState(false);
    const [isVerificationModalOpen, setVerificationModalOpen] = useState(false);
    
    const [files] = useState<Files>(new Files(data.vault));
    const [credentials] = useState<Credentials>(new Credentials(data.vault, files));

    const removeFile = async (cid: string) => {
        setLoading(true);
        const newFiles = await credentials.remove(cid);
        setDefaultFileList({ data: newFiles });
        setLoading(false);
        setDeleting(false);
    }
        
    useEffect(() => {
        setLoading(true);
        credentials.list().then((index: any) => {
            setDefaultFileList({ data: index });
            setLoading(false);
        });
    // eslint-disable-next-line
    }, [0]);

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: '.json',
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);
            const utf8 = Buffer.from(bytes).toString('utf8');

            const cert : NCred = JSON.parse(utf8)
            const rawData = cert.confidential ? await data.vault.decrypt(cert.confidential.owner) : {};
            
            const signer = await recoverSignature({message: cert.hash, signature: cert.id }).signer;
            const source = await recoverSignature({message: cert.hash, signature: cert.source }).signer;
            
            let dataCopy : any = {...rawData};
            dataCopy.__selfIssued__ = source === signer;
            dataCopy.__issuer__ = signer;
            dataCopy.__source__ = source;
            
            setCredential({ data: { data: dataCopy, raw: cert, issue: true } });
            setCreateModalOpen(true);
            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                // forceUpdate();
            } 
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    return (
    <>
        { isLoading ?
            <>
                <Alert
                    message="Blockchain magic is happening..."
                    type="warning"
                />
                <br></br>
                <br></br>
                <Spin indicator={antIcon} ></Spin>
            </>
            :
            <>
                { isDeleting ?
                    <Alert
                        
                        message={`Please confirm the deletion of file: ${isDeletingId.Name} | (${isDeletingId.Id})`}
                        type="warning"
                        action={
                            <Space direction="vertical">
                                <Button size="small" type="primary" danger onClick={async () => { await removeFile(isDeletingId.Id)}}>
                                    Remove
                                </Button>
                                <Button size="small" danger type="dashed" onClick={() => setDeleting(false) }>
                                    Cancel
                                </Button>
                            </Space>
                        }
                    />
                :
                    <></>
                }
                <Table 
                    dataSource={defaultFileList.data.map((x, i) => {
                        x.key = i;
                        return x;
                    })}
                    pagination={{hideOnSinglePage: true}}
                >

                    <Column
                        title="CID"
                        key="type"
                        render={(_: any, record: any) => (
                            truncate(record.cid)
                        )}
                    />
                    <Column
                        title="Owner"
                        key="name"
                        render={(_: any, record: any) => (
                            JSON.parse(record.meta).owner
                        )}
                    />
                    <Column
                        title="Type"
                        key="type"
                        render={(_: any, record: any) => (
                            CredentialTemplates[JSON.parse(record.meta).type].name
                        )}
                    />
                    <Column
                        title="Created"
                        key="created"
                        render={(_: any, record: any) => (
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.created / 1000)} />
                        )}
                    />
                    <Column
                        title="Delete"
                        key="delete"
                        render={(_: any, record: any) => (
                            <Button 
                            type="default" 
                            block
                            onClick={async ()=> { setDeletingId({ Id: record.cid, Name: record.meta}); setDeleting(true) } }
                            >
                                <DeleteOutlined />
                            </Button>
                        )}
                    />
                    <Column
                        title="View"
                        key="view"
                        render={(_: any, record: any) => (
                            <Button 
                            type="default" 
                            block
                            onClick={async () => {
                                const secret = record.secret;
                                const cid = record.cid;

                                const encryptedData = await files.get(cid);

                                const rawData = decryptBySecret(secret, encryptedData)

                                let _data = JSON.parse(rawData);
                                
                                const signer = await recoverSignature({message: _data.hash, signature: _data.id }).signer;
                                const source = await recoverSignature({message: _data.hash, signature: _data.source }).signer;
                                const pubKey = data.vault.getWalletData().publicKey;

                                const vcCredential = await data.vault.decrypt(signer === pubKey ? _data.confidential.issuer : _data.confidential.owner);

                                let dataCopy = {...vcCredential};
                                dataCopy.__owner__ = JSON.parse(record.meta).owner;
                                
                                dataCopy.__selfIssued__ = source === signer;
                                dataCopy.__issuer__ = signer;
                                
                                setCredential({ data: { data: dataCopy, raw: _data } });
                                setQueryModalOpen(false);
                                setCreateModalOpen(true);           
                                
                            }}
                            >
                                <EyeOutlined />
                            </Button>
                        )}
                    />
                </Table>

                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                    <FileProtectOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag a "Credential" to this area to either Re-Issue or Save</p>
                    <p className="ant-upload-hint">
                    Only json files in a credential format will work.
                    </p>
                </Dragger>
            </>
        }

        <FloatButton.Group
            type="default"
            style={{ right: 50 }}
            icon={<HomeOutlined />}
        >
            <FloatButton tooltip={<div>Verify Proof</div>} icon={<VerifiedOutlined />} onClick={()=> {setCreateModalOpen(true);setVerificationModalOpen(true);setQueryModalOpen(false); forceUpdate();}} />
            <FloatButton tooltip={<div>Create Query</div>} icon={<FileSearchOutlined />} onClick={()=> {setCreateModalOpen(true);setVerificationModalOpen(false);setQueryModalOpen(true); forceUpdate();}} />
            <FloatButton tooltip={<div>Issue Credential</div>} icon={<EditOutlined />} onClick={()=> {setCreateModalOpen(true);setVerificationModalOpen(false);setQueryModalOpen(false); forceUpdate();}} />
            <FloatButton tooltip={<div>Logout</div>} icon={<LogoutOutlined />} onClick={async () => {
                // const permissions = await ethereum.request({
                //   method: 'wallet_requestPermissions',
                //   params: [{
                //     eth_accounts: {},
                //   }]
                // });
                // setWalletData({ address: null });
                // setCurrent(0);
                data.logout();
            }}/>
        </FloatButton.Group>

        <Modal title={isQueryModalOpen ? "Query" : "Credential"} closable={false} open={isCreateModalOpen} footer={null}>
            <Credential 
                vault={data.vault}
                credential={credential ? credential.data : undefined}
                credentials={credentials}
                query={isQueryModalOpen}
                verifier={isVerificationModalOpen}
                closeModal={() => {
                    setCreateModalOpen(false);
                    setQueryModalOpen(false);
                    setVerificationModalOpen(false);
                    setCredential(null);
                    setLoading(true);
                    forceUpdate();
                    credentials.list().then((index: any) => {
                        setDefaultFileList({ data: index });
                        setLoading(false);
                    });
                }}
            />
        </Modal>
    </>
    );
}