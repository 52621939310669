import React, { useState, useEffect } from 'react';
import { Form, InputNumber, Col, Row, Button, Divider, Typography, Input, Table, Alert, DatePicker } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';

import { Tokens, Deals, Files, zeroAddress } from '@notcentralised/notvault-sdk';

import dayjs from 'dayjs'

const { Column } = Table;
const { Paragraph } = Typography;

export const AcceptDeal: React.FC<any> = (data) => {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);

    const [tokens] = useState<Tokens>(new Tokens(data.vault));
    const [files] = useState<Files>(new Files(data.vault));
    const [deals] = useState<Deals>(new Deals(data.vault, tokens, files));

    useEffect(() => {
        const id = setInterval(() => {
            form.resetFields();
        }, 250); 
        
        return () => clearInterval(id); 
    });

    const onSend = async (values: any) => {    
        setLoading(true);

        // eslint-disable-next-line
        const { balance, decimals} = await tokens.tokenBalance(values.denomination);

        // await deals.accept(
        //     values.denomination,
        //     values.owner,
        //     BigInt(values.initial) * decimals,
        //     data.vault.confidentialOracle ? data.vault.confidentialOracle.address : zeroAddress,
        //     values.oracle_owner,

        //     values.oracle_key_sender,
        //     values.oracle_value_sender,
        //     values.oracle_key_recipient,
        //     values.oracle_value_recipient,

        //     values.unlock_sender ? values.unlock_sender.unix() : 0,
        //     values.unlock_receiver ? values.unlock_receiver.unix() : 0,
        //     values.tokenId,
        //     values.expiry ? values.expiry.unix() : 0
        //     );

        form.resetFields();
        data.closeModal();
        setLoading(false);
    };

    const changeDates = (deal: any) => {
        let d =  { ...deal };
        d.unlock_sender = dayjs(deal.unlock_sender * 1000)
        d.unlock_receiver = dayjs(deal.unlock_receiver * 1000)
        d.expiry = dayjs(deal.expiry * 1000)
        
        return d;
    }

    return (
        <>
            <Form
                name="commitDeal"
                form={form}
                onFinish={onSend}
                initialValues={changeDates(data.deal)}
                
                labelCol={{ span: 8 }}
            >
                <Divider orientation="left"></Divider>
                <Paragraph style={{textAlign:'left'}}>
                You will transfer tokens from your vault to your liquid balance.
                </Paragraph>
                <Divider orientation="left"></Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Counterpart" 
                            name="counterpart"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Owner" 
                            name="owner"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Deal Information</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Name" 
                            name="name"
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Denomination" 
                            name="denomination"
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Id" 
                            name="tokenId"
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Description" 
                            name="description"
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Notional" 
                            name="notional"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 
                                disabled
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Initial Commitment" 
                            name="initial"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }}
                                disabled
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Payment Conditions</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Unlock Payer" 
                            name="unlock_sender"
                        >
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Unlock Payee" 
                            name="unlock_receiver"
                        >
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Expiry" 
                            name="expiry"
                        >
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Approver" 
                            name="oracle_owner"
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Key" 
                            name="oracle_key"
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Value" 
                            name="oracle_value"
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    data.deal.files.data.length > 0 ?
                    <>
                        <Divider orientation="left">Files</Divider>
                        <Table 
                            dataSource={data.deal.files.data.map((x:any, i:number) => { x.key=i; return x})}
                            pagination={{hideOnSinglePage: true}}
                        >
                            <Column
                                title="Name"
                                key="name"
                                render={(_: any, record: any) => (
                                    record.name
                                )}
                            />                    
                            <Column
                                title="Download"
                                key="download"
                                render={(_: any, record: any) => (
                                    <Button 
                                        type="default" 
                                        block
                                        onClick={async () => {
                                            const name = record.name;
                                            const _data = record.data;
                                            const file = new File([Buffer.from(_data,"base64")], name, {type: 'application/octet-stream'});
                                            const element = document.createElement("a");
                                            element.href = URL.createObjectURL(file);
                                            element.download = name;
                                            document.body.appendChild(element);
                                            element.click();
                                        }}
                                    >
                                        <CloudDownloadOutlined />
                                    </Button>
                                )}
                            />
                        </Table>
                    </>
                    :
                    <></>
                }

                <Divider orientation="left"></Divider>

                {
                    data.balanceData.privateBalance < data.deal.initial ? 
                    <>
                        <Alert
                            
                            message="The private balance is not enough to commit the initial amount..."
                            type="error"
                        />
                        <br></br>
                    </>
                    :
                    <></>
                }
                
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => { form.resetFields(); setLoading(false); data.closeModal(); }}
                        disabled={false}
                    >
                        Cancel
                    </Button>
                    <Button 
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={data.balanceData.privateBalance < data.deal.initial}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Commit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}